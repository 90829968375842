@import-normalize;
@import 'styles/variables.scss';
@import 'styles/text.scss';

/* Import google fonts */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Roboto+Condensed:wght@400;700&display=swap');

body {
    background-image: linear-gradient(135deg, #f2f2f2 25%, #ffffff 25%, #ffffff 50%, #f2f2f2 50%, #f2f2f2 75%, #ffffff 75%, #ffffff 100%);
    background-size: 1414.21px 1414.21px;

    font-family: 'Open Sans', sans-serif;
    font-size: 16px;

    color: $color-dark;
}

.main {
    max-width: $width-desktop;
    margin: 0 auto;
}



h1 {
    @extend %heading-text;
    font-style: italic;
}

.croft {
    --color-primary: #1e5224;
    --color-primary-faded: #e9eee9;
    --image-logo-width: 150px;
}