@import 'styles/variables.scss';
@import 'styles/text.scss';

.box {
    width: 100%;
    border: 1px solid $color-primary;

    padding-top: 0;
}

.title {
    @extend %heading-text;

    padding: 5px;
    font-size: 1.2em;

    &::before {
        content: '';
        width: 22px;
        height: 32px;
        background-color: $color-primary;
        display: inline-block;
        transform: skew(-30deg);
        vertical-align: -30%;
        margin-right: 30px;
        margin-left: 15px;
    }
}

.content {
    margin-left: 70px;
    padding: 5px;
}