@import 'variables.scss';

// Various text styles
%heading-text {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
}


// Links
%header-link {
    color: $color-dark;

    border-bottom: none;
    text-decoration: none;

    &:hover {
        border-bottom: 1px solid $color-primary;
    }
}