@import 'styles/variables.scss';
@import 'styles/text.scss';

@mixin arrow-border($color) {
    background-color: $color;

    &::before {
        border-color: $color $color $color transparent;
    }

    &::after {
        border-color: transparent transparent transparent $color;
    }
}

.pointer {
    @extend %heading-text;

    position: relative;
    display: inline-block;
    height: 40px;
    flex: 1;
    margin-right: 20px;
    margin-left: 40px;

    text-align: center;
    vertical-align: middle;

    font-size: 1.12em;
    font-style: italic;

    &:first-child {
        margin-left: 0;
    }

    &>span {
        display: inline-block;
        position: relative;
        width: 100%;
        height: 100%;
        margin-top: 7px;
    }
}

.pointer::after {
    content: '';
    position: absolute;
    right: -20px;
    bottom: 0;
    width: 0;
    height: 0;

    border-style: solid;
    border-width: 20px 0 20px 20px;
}

.pointer::before {
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 0 20px 20px;
}

.active {
    @include arrow-border($color-primary);
    color: $color-white;
}

.upcoming {
    color: $color-grey-dark;
    @include arrow-border($color-grey-light);
}

.completed {
    color: $color-grey-light;
    @include arrow-border($color-primary-faded);
}