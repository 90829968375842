@import 'styles/variables.scss';
@import 'styles/text.scss';

.header {
    display: flex;
    justify-content: space-between;

    width: 100%;
    max-width: $width-desktop;
    margin: 0 auto;

    margin-top: 35px;
}

.logo {
    max-width: $image-logo-width;
}

.details {
    @extend %heading-text;

    text-align: right;
    font-size: 1.2em;

    ul {
        list-style: none;
    }

    li {
        margin-top: 5px;
    }

    a {
        @extend %header-link;
    }
}

.icon {
    color: $color-primary;
    margin-right: 7px;
}