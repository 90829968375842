@import 'styles/variables.scss';
@import 'styles/text.scss';

.successBox {
    border: 1px solid $color-green;
    background: $color-green-light;
    color: $color-green-dark;

    padding: 15px 30px;
    margin-bottom: 50px;

    strong {
        @extend %heading-text;
    }

    svg {
        margin-right: 10px;
    }
}

.detailsBox {
    border: 1px solid $color-grey-light;
    padding: 15px;
    margin-top: 50px;

    dl {
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        dt {
            @extend %heading-text;
            display: block;
            font-size: 1.1em;
            margin-bottom: 3px;
        }

        dd {
            margin: 0;
        }
    }
}

.postalAddress {
    &>span {
        display: block;
    }
}

.guests {
    &>span {
        display: block;
    }
}