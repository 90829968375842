@import 'styles/variables.scss';
@import 'styles/text.scss';

.checkbox {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;

    input { display: none; }

    input:checked + span>.checkmark {
        display: block;
    }
}

.checkmarkContainer {
    display: block;
    position: relative;
    width: 40px;
    height: 40px;
    border: 1px solid $color-grey-light;
    margin-right: 15px;
}

.checkmark {
    display: none;
    position: absolute;
    top: 12px;
    left: 12px;
    color: $color-dark;
}

.text {
    @extend %heading-text;
    strong {
        font-weight: bold;
        display: block;
        color: $color-dark;
    }

    small {
        font-weight: normal;
        color: $color-grey-dark;
    }
}