@import 'styles/text.scss';

.details {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 10px;
    row-gap: 10px;

    margin-top: 0;

    &>div {
        width: 100%;

        dt {
            @extend %heading-text;
            display: block;
        }

        dd {
            display: block;
            margin: 0;

            &>span {
                display: block;
            }
        }
    }
}