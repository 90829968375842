@import 'styles/variables.scss';
@import 'styles/text.scss';

.box {
    border: 1px solid $color-primary;
    width: 100%;

    margin-top: 15px;
}

.header {
    @extend %heading-text;

    border-bottom: 1px solid $color-primary;
    min-height: 50px;

    padding: 5px;
    font-size: 0.8em;

    &>h2 {
        &:before {
            content: '';
            border: solid $color-primary;
            border-width: 0 6px 6px 0;
            display: inline-block;
            padding: 10px 10px 5px 5px;
            transform: translateY(3px) scale(0.6, 1.1) rotate(-45deg);

            margin-right: 15px;
        }
    }
}

.content {
    padding: 15px;
}