.guestRow {
    display: flex;
    align-items: flex-end;
}

.guestInput {
    flex-grow: 1;
    flex-basis: 0;

    & input[type=text] {
        width: 90%;
    }
}

.removeGuest {
    margin-bottom: 30px;
}

.addGuest {

}