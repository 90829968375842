.detailsForm {
    display: flex;
}

.col {
    flex-grow: 1;
    flex-basis: 0;

    input[type=text] {
        width: 80%;
    }
}