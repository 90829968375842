@import 'styles/forms.module.scss';

.submit {
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: right;

    button {
        @extend .button;
    }
}