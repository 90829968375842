@import './variables.scss';
@import './text.scss';

.formField {

    margin-bottom: 30px;

    label {
        @extend %heading-text;
        display: block;
    
        margin-bottom: 7px;
    }

    input[type=text] {
        display: block;
        padding: 7px;

        border: 1px solid $color-grey-light;

        font-size: 1em;
        font-family: 'Open Sans', sans-serif;
    }
}

.button {
    @extend %heading-text;

    background: $color-primary;
    color: $color-white;
    padding: 0.5em 1.5em;

    font-size: 1.2em;
    border: 0;

    &:disabled {
        background: $color-grey-light;
        color: $color-grey-dark;
    }
}

.redButton {
    @extend .button;

    background: $color-red;
}