@import 'styles/forms.module.scss';

.form {
    display: flex;
    align-items: flex-end;

    width: 100%;
    
    &>div {
        flex-grow: 1;
        margin-bottom: 0;

        input {
            width: 80%;
        }
    }
}



.buttonContainer {
    flex-grow: 1;

    button {
        @extend .button;
        width: 100%;
    }
}