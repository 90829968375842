:root {
  --color-primary: #f7921e;
  --color-primary-faded: #feedee;
  --color-dark: #2a2626;
  --color-grey-light: #ccc;
  --color-grey-dark: #797877;
  --color-white: #fff;
  --color-red-fade: #f2dede;
  --color-red: #a94442;
  --color-green: #d6e9c6;
  --color-green-light: #dff0d8;
  --color-green-dark: #3c763d;
  
  --image-logo-width: 200px;
}

// Colors
$color-primary: var(--color-primary);
$color-primary-faded: var(--color-primary-faded);
$color-dark: var(--color-dark);
$color-grey-light: var(--color-grey-light);
$color-grey-dark: var(--color-grey-dark);
$color-white: var(--color-white);
$color-red-fade: var(--color-red-fade);
$color-red: var(--color-red);
$color-green: var(--color-green);
$color-green-light: var(--color-green-light);
$color-green-dark: var(--color-green-dark);

// Widths, heights and breakpoints
$width-desktop: 1230px;

// Images
$image-logo-width: var(--image-logo-width);