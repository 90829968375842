@import 'styles/variables.scss';

.errors {
    background: $color-red-fade;
    color: $color-red;
    padding: 15px;
    margin-bottom: 30px;
    margin-top: 30px;
}

.list {
    list-style: square;
    margin: 0;
}